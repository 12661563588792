import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "| 스테이지파이브 ‘핀다이렉트샵’, 11일부터 중고 스마트폰 단말기 판매 + 전용 요금제 런칭",
  "| 최저가 아닐 시 할인권 프로모션…2단계 검수로 A급 품질 보상",
  "| 5G 스마트폰 판매 및 알뜰폰 요금제에 이어 중고폰까지 라인업, 고객 선택권 확대",
]

const content = () => (
  <div>
    <style>
      {
        "\
          .pressDetailPage .pressDetailBody .contentArea .underline {\
            text-decoration: underline;\
          }\
          .pressDetailPage .pressDetailBody .contentArea .textbox {\
            border: 1px solid black;\
            line-height: 20px;\
            margin: auto;\
            padding: 10px;\
            text-align: center;\
            width: 250px;\
          }\
          .pressDetailPage .pressDetailBody .contentArea .descImage.package .img {\
            margin: auto;\
            width: 300px;\
          }\
        "
      }
    </style>
    <br />
    <div className="descImage">
      <div>
        <img src="/images/press/12/qxHQUYRR.png" alt="Figure1" />
      </div>
      <div className="desc">
        △스테이지파이브(공동대표 이제욱ㆍ서상원)가 운영하는 ‘핀다이렉트샵’이 <br className="sf-web" />
        11일 중고폰 단말기 및 전용 요금제 서비스를 런칭한다.
      </div>
    </div>
    <p>
      <br />
      가격과 품질을 믿을 수 있는 중고 스마트폰을 한눈에 비교하고, 요금제까지 함께 구매하는 길이 열린다.
      <br />
      <br />
      스테이지파이브(공동대표 이제욱ㆍ서상원)가 운영하는 ‘핀다이렉트샵’은 11일(화) ‘핀다이렉트 중고폰’ 서비스를 런칭한다.
      <br />
      <br />
      ‘핀다이렉트 중고폰’은 검증된 중고 스마트폰 단말기(이하 중고폰) 판매와 중고폰 전용 요금제를 한번에 비교하고 신청할 수 있는
      서비스이다. 중고폰 공기계만 단독 구입도 가능하다.
      <br />
      <br />
      핀다이렉트 중고폰은 △최저가 △꼼꼼한 2단계 품질 케어(핀다이렉트 케어) △안심 교환/환불 정책이 특징이다.
      <br />
      <br />
      특히 런칭 프로모션으로 핀다이렉트 중고폰 ‘최저가 보상 제도’ 도입하여 핀다이렉트 중고폰이 최저가가 아닐 시 이용자에게
      할인권을 보상하는 파격 정책을 펼친다.
      <br />
      <br />
      <br />
      <span className="bold">| 중고폰도 ‘쉽고, 싸고, 마음 편하게!’ … 핀다이렉트 케어와 안심 보상 정책</span>
      <br />
      <br />
      새 스마트폰이나 다름 없는 A급 단말 품질을 위해 총 2단계 ‘핀다이렉트 케어’가 필수로 이뤄진다.
      <br />
      <br />
      먼저, 전문업체 ‘블란코(blancco)’의 데이터 삭제 솔루션을 사용하여 국제 기준에 맞춰 중고폰에 남아있는 기존 개인 정보를 완벽히
      삭제한다. 이어 핀다이렉트 전문 크루(crew)가 기초, 외관, 기능 등에 해당하는 50여개 체크리스트를 바탕으로 꼼꼼히 검수한다.
      <br />
      <br />
      해당 중고폰에 관한 체크리스트는 구매 고객이 직접 받아볼 수 있도록 제품과 함께 동봉해 전달한다.꼼꼼하고 깔끔한 리패키징도
      고객 만족을 이끌 요인이다.
      <br />
      <br />
    </p>
    <div className="descImage package">
      <div className="img">
        <img src="/images/press/12/WGxFCuvz.png" alt="Figure2" />
        <img src="/images/press/12/uAMrsTpQ.png" alt="Figure3" />
      </div>
      <div className="desc">
        △스테이지파이브(공동대표 이제욱ㆍ서상원)가 운영하는 ‘핀다이렉트샵’이 <br className="sf-web" />
        11일 중고폰 단말기 및 전용 요금제 서비스를 런칭한다. 사진은 핀다이렉트 중고폰 고객에게 직접 전달되는 체크리스트와
        안내서(위)와 패키지 및 내용물 전체(아래)
      </div>
    </div>
    <p>
      <br />
      <br />
      이에 더하여 진행하는 ‘안심 보상 서비스’는 제품 이상 시 14일 내 무료 교환 및 환불, 제품 결함이 발생하면 30일 내 무상 A/S 및
      교환을 제공한다.
      <br />
      <br />
      <span className="bold">| 중고폰도 ‘쉽고, 싸고, 마음 편하게!’ … 핀다이렉트 케어와 안심 보상 정책</span>
      <br />
      <br />
      핀다이렉트 중고폰의 라인업 역시 저렴하고 인기 있는 기종으로 구성됐다.
      <br />
      <br />
      5월 현재 판매 예정인 단말은 아이폰 11을 비롯, 갤럭시 노트10 5G, 갤럭시 S10 5G 등 총 5종이다.
      <br />
      <br />
      <div className="textbox">
        <span className="title bold underline">핀다이렉트샵 중고폰 단말 라인업</span>
        <br />
        - iPhone 11 : 64G / 128G / 256G
        <br />
        - iPhone SE : 128G
        <br />
        - 노트 10 5G : 256G
        <br />
        - S10 : 128G / 512G
        <br />
        - S9+ : 64G / 256G
        <br />
      </div>
      <br />
      <br />
      스테이지파이브 서상원 공동대표는 “통신 구독의 새 패러다임을 제시하고자 달려온 스테이지파이브는 이번 ‘핀다이렉트 중고폰’
      런칭으로 중고폰 구매에도 새 패러다임을 열고자 한다”면서 “다시 쓰는 가치를 아는 고객들께 안전하고 쾌적한 통신 라이프를
      선사함과 동시에 새롭고 유용한 서비스로 보답하겠다”고 포부를 전했다.
      <br />
      <br />
      ‘핀다이렉트 중고폰’은 카카오톡 ‘핀다이렉트샵’ 내 신설된 ‘중고폰’탭을 누르거나, 핀플레이(
      <a href="https://www.pinplay.co.kr" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "underline" }}>
        https://www.pinplay.co.kr
      </a>
      ) PC화면에 접속하여 ‘통신서비스’ 탭을 클릭하면 만날 수 있다. 중고폰 구매 및 개통 문의는 카카오톡 채널 ‘핀다이렉트샵’ 내
      ‘상담하기’ 탭에서 가능하다. (평일 10시~18시, 주말 및 공휴일 휴무)
      <br />
      <br />
    </p>
  </div>
)

const press12 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브 제휴협력실"
      writer="커뮤니케이션팀 pr@stagefive.com "
      date="2021년 5월 10일 즉시 배포 가능 (5월 11일 조간 요청)"
      pageInfo="*총 3매/ 첨부: 사진 3매"
      title="안전하고 합리적인 중고폰 구매, ‘핀다이렉트 케어 중고폰’!"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press12
