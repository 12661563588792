import React, { Component } from "react"
import PropTypes from "prop-types"

import "../styles/pressDetail.css"

class PressDetail extends Component {
  static propTypes = {
    dep: PropTypes.string.isRequired,
    writer: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    dateType: PropTypes.string,
    pageInfo: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subTitles: PropTypes.array.isRequired,
    content: PropTypes.func.isRequired,
  }

  render() {
    return (
      <div className="mainContent">
        <div className="pressDetailPage">
          <div className="pressDetailHeader">
            <div className="titleArea">
              <span>보도자료</span>
              {/* <div className="logo">
                <img src="/images/logo_a_kakao_vertical.jpg" alt="StageFive" />
              </div> */}
            </div>
            <div className="writerInfoArea">
              <div className="dep">
                <span dangerouslySetInnerHTML={{ __html: this.props.dep }}></span>
              </div>
              <div className="writer">
                <span dangerouslySetInnerHTML={{ __html: this.props.writer }}></span>
              </div>
            </div>
            <div className="bar">
              <img src="/images/press/bar.png" alt="bar" />
            </div>
            <div className="pageInfoArea">
              {this.props.dateType === "embargo" && (
                <div className="date bold embargo">
                  {`<`}엠바고요청{`>`} : {this.props.date}
                </div>
              )}
              {this.props.dateType !== "embargo" && <div className="date">배포일자 : {this.props.date}</div>}
              <div className="pageInfo">{this.props.pageInfo}</div>
            </div>
          </div>
          <div className="pressDetailBody">
            <div className="titleArea">
              <div className="title">
                <div dangerouslySetInnerHTML={{ __html: this.props.title }}></div>
              </div>
              <div className="subTitle">
                {this.props.subTitles.map((subTitle, idx) => {
                  return (
                    <span key={idx} className="text">
                      {subTitle}
                    </span>
                  )
                })}
              </div>
            </div>
            <div className="contentArea">
              <this.props.content></this.props.content>
              <span className="end">{`<끝>`}</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PressDetail
